import './App.css';
import {BeachAccess, CalendarMonth} from "@mui/icons-material";
import {Button, Grid, Paper, useMediaQuery} from "@mui/material";
import Footer from "./Footer";
import {themeColour1, themeTextColorOnWhite} from "./index";
import InnerHeader from "./InnerHeader";

function StandardFrame({preHeaderText, headerText, postHeaderText, headerImageUrl, mainBody}) {

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"))
    const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up("lg"))


    return (
    <div className={"App"}>
        <div style={{width:"100%", minHeight:"700px", backgroundImage:headerImageUrl, backgroundSize:"cover", backgroundPosition: "center"}}>
            <InnerHeader />
            <div style={{marginTop: "115px"}}>
                <div style={{color: "white", fontStyle: "italic", fontSize: isLargeScreen?"23px":"18px"}}>{preHeaderText}</div>
                <h1 style={{marginTop:"0px", color: "white", fontSize: isLargeScreen?"65px":"50px"}}>{headerText}</h1>
                {/*{ postHeaderText && <div style={{marginTop:"0px", color: "white", fontSize: isLargeScreen?"25px":"20px"}}>{postHeaderText}</div> }*/}
                <div style={{marginTop:"0px", color: "white", fontSize: isLargeScreen?"25px":"20px"}}>
                    <div>Alternatively, see:</div>
                    <a style={{color: "white", fontWeight: "bolder", fontSize: "1.5em"}} href={"https://fileycottage.com/"}>Family Cottage at Filey Bay</a>
                </div>
            </div>
        </div>

        <Grid container style={{marginTop:"-100px"}}>
            {/*TODO: change this to flexbot, with max size area in middle...*/}
            <Grid item sm={1}></Grid>
            <Grid item sm={10}>
                {mainBody}
                <br />
            </Grid>
            <Grid item sm={1}></Grid>
        </Grid>

        <Footer />
        <br />

        <Button size={"large"} variant={"contained"} startIcon={<CalendarMonth />} style={{position: "fixed", right:"20px", bottom: "20px", color: "white"}} onClick={() => {window.location.href="/reservation"}}>Book Now!</Button>

    </div>
  );
}

export default StandardFrame;
