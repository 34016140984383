import './App.css';
import {CopyrightOutlined, DirectionsWalk, DriveEta} from "@mui/icons-material";

function Spa() {
  return (
    <div style={{marginTop:"20px", display: "flex", justifyContent:"center"}}>
        {/*<a href={"/"} style={{margin:"10px"}}>Contact Us</a>*/}
        <div style={{display:"flex", color: "grey", margin:"10px"}}><CopyrightOutlined /> <span style={{marginLeft: "3px"}}>Southall Property Ltd</span></div>
    </div>
  );
}

export default Spa;
