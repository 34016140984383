import './App.css';
import {DirectionsWalk, DriveEta} from "@mui/icons-material";
import StandardFrame from "./StandardFrame";
import {Paper} from "@mui/material";
import {StyledWhiteLongDescriptorBlock, themeTextColorOnWhite} from "./index";

function Playground() {
  return (
      <StandardFrame preHeaderText={"Your Own"} headerText={"Private Playground"} headerImageUrl={"url('/playground/playground1.jpg')"}
                     mainBody={
                         <StyledWhiteLongDescriptorBlock>
                             <h2 style={{fontStyle: "italic"}}>"A Safe, Secure and Fun space for the little ones while you relax"</h2>

                             <div>
                                 Our playground is exclusively for guests of our <a href={"/"}>holiday home</a>.
                             </div>
                             <div style={{fontStyle:"italic"}}>
                                 <h3>Playground Rules</h3>
                                 <div>For Children aged 2-12 years.</div>
                                 {/*TODO: confirm ages*/}
                                 <div>If you see any damage, please do not use the equipment and report it immediately.</div>
                                 <div>Children <b>must</b> be supervised at all times.</div>
                                 <div>Exclusively for guests of the holiday home.</div>
                                 <div>Usage is at your (and your child's) own risk.</div>
                                 <br />
                             </div>
                         </StyledWhiteLongDescriptorBlock>
                     } />
  );
}

export default Playground;
