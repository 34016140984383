import './App.css';
import {Button, Grid} from "@mui/material";
import {
    gapBetweenSections, StyledColouredLongDescriptorBlock,
    StyledColouredShortDescriptorBlock,
    StyledImageBlock, StyledWhiteLongDescriptorBlock,
    themeTextColorOnWhite, titleFontHeadingSize
} from "./index";
import StandardFrame from "./StandardFrame";
import SearchAvailabilityControl from "./SearchAvailabilityControl";

function Reservation() {

  return (
      <StandardFrame preHeaderText={<div>The Seaside Awaits</div>} headerText={<div>Reserve Our Holiday Home Now</div>} headerImageUrl={"url('/reservation/beach.jpg')"}
                       mainBody={
            <div>
                <StyledWhiteLongDescriptorBlock style={{justifyContent: "center"}}>
                    <SearchAvailabilityControl navigateToReservationPage={false} />

                    <div>
                        <h2 style={{paddingTop:"40px", color: "red", textAlign: "center"}}>Accommodation under refurbishment. Please check back soon for bookings ahead of our launch in 2023.</h2>
                    </div>
                </StyledWhiteLongDescriptorBlock>

            </div>
        } />
  );
}

export default Reservation;
