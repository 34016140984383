import StandardFrame from "./StandardFrame";
import {StyledWhiteLongDescriptorBlock} from "./index";

function Accommodation() {
  return (
      <StandardFrame headerText={"Accommodation"} headerImageUrl={"url('/house/outside1.jpg')"}
                     mainBody={
                         <StyledWhiteLongDescriptorBlock>
                             <div>
                             <h2>The House</h2>
                                 <div>
                                     Packed with family fun, our Cayton Bay holiday home has everything you need for a fantastic holiday on the <a href={"/location/"}>North East Coast</a>.
                                 </div>

                                 <h2>Dining Room</h2>
                                 <div>The conservatory dining room is positioned at the side of the house and has fantastic sea views. It has a large six seater table (plus a highchair) and an additional sofa for relaxation outside of mealtimes. </div>

                                 <h2>Living Room</h2>
                                 <div>With three double sofas, there is ample space for the whole family. There is also a large TV, for when you are not enjoying the breathtaking views.</div>

                                 <h2>Kitchen</h2>
                                 <div>Modern fitted kitchen, complete with all the accessories required to enjoy your holiday to the fullest, including:</div>
                                 <ul>
                                     <li>Fridge</li>
                                     <li>Freezer</li>
                                     <li>Oven</li>
                                     <li>Microwave</li>
                                     <li>Toaster</li>
                                     <li>Pots & Pans</li>
                                     <li>Crockery & Cutlery</li>
                                     <li>Cups & Glasses</li>
                                 </ul>

                                 <h2>Bedroom 1</h2>
                                 <div>The master bedroom contains a double bed and a fitted wardrobe. There is also a chair, which can be converted into a single sofabed (if required). There is also a hair dryer available in this room.</div>

                                 <h2>Bedroom 2</h2>
                                 <div>The second bedroom contains a triple sleeper (double bed on the bottom, single on top).</div>

                                 <h2>Bathroom</h2>
                                 <div>A bright, modern & comfortable bathroom, containing a bath with an overhead shower.</div>

                                 <h2>Outside Terrace</h2>
                                 <div>Eat breakfast in style, with panoramic views from Scarborough Castle and across Cayton Bay beach.</div>

                                 <h2>Other Amenities</h2>
                                 <div>Hoover</div>
                                 <div>Outside, the garden of our <a href={"/"}>Holiday Home</a> boasts:</div>

                                 <h3>Spa</h3>
                                 <a href={"/spa/"}>Find out more about the private spa.</a>

                                 <h3>Games Room</h3>
                                 <a href={"/games-room/"}>Find out more about the games room.</a>

                                 <h3>Playground</h3>
                                 <a href={"/playground/"}>Find out more about the playground.</a>
                                 <div>Cayton Bay Cottage has a safe and secure children's playground.</div>

                                 <br />
                             </div>

                         </StyledWhiteLongDescriptorBlock>
                     } />
  );
}

export default Accommodation;
