import './App.css';
import {Dialog, Grid, ImageList, ImageListItem, ImageListItemBar, Paper, useMediaQuery} from "@mui/material";
import StandardFrame from "./StandardFrame";
import {useState} from "react";
import {StyledWhiteLongDescriptorBlock, themeTextColorOnWhite} from "./index";

function GamesRoom() {

    const [expandedUrl, setExpandedUrl] = useState("")

    function imageNode(imgUrl, title, subtitle) {
        return <ImageListItem onClick={() => setExpandedUrl(imgUrl)}>
                <img style={{maxHeight: "300px", cursor:"zoom-in"}} src={imgUrl+"?w=248&fit=crop&auto=format"} srcSet={imgUrl+"?w=248&fit=crop&auto=format&dpr=2 2x"} alt={title} loading="lazy" />
                <ImageListItemBar title={title} subtitle={<span>{subtitle}</span>} position="below" />
            </ImageListItem>
    }

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"))
    const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up("lg"))

  return (
      <StandardFrame preHeaderText={"Exclusive"} headerText={"Family Games Room"} headerImageUrl={"url('/games-room/games-room-inside1.jpg')"}
                     mainBody={
                         <StyledWhiteLongDescriptorBlock>
                             <Dialog open={Boolean(expandedUrl)} onClose={() => setExpandedUrl("")}>
                                 <div>
                                     <div style={{width:"100%", textDecoration:"underline", textAlign:"center", padding:"5px", cursor:"pointer"}} onClick={() => setExpandedUrl("")}>Close</div>
                                     <img src={expandedUrl} />
                                 </div>
                             </Dialog>
                             <h2>Exclusively yours for your entire stay.</h2>
                             <div style={{fontStyle: "italic"}}>All games are completely free to guests of the <a href={"/"}>holiday home</a> (and don't require coins).</div>
                             <ImageList variant="standard" cols={isSmallScreen ? 1 : isLargeScreen ? 3 : 2} gap={5}>
                                 { imageNode("/games-room/games-room-outside1.jpg", "Exterior", "Charming Log Cabin") }
                                 { imageNode("/games-room/pool-table.jpg", "Pool Table", "6ft, new for 2023") }
                                 { imageNode("/games-room/table-football.jpg", "Table Football", "") }
                                 { imageNode("/games-room/packman.jpg", "Packman Arcade Game", "Complete with several other games") }
                                 { imageNode("/games-room/pawpatrol-game.jpg", "Paw Patrol Arcade Game", "Perfect for the little ones") }
                             </ImageList>
                         </StyledWhiteLongDescriptorBlock>
                     }
      />
  );
}

export default GamesRoom;
