import './App.css';
import {CalendarMonth, Image} from "@mui/icons-material";
import {Button, Card, Grid, Menu, MenuItem, Paper, styled} from "@mui/material";
import Footer from "./Footer";
import {themeColour1} from "./index";
import {useState} from "react";

function InnerHeader() {

    const [roomsAnchorEl, setRoomsAnchorEl] = useState(null)
    const roomsMenuOpen = Boolean(roomsAnchorEl);

    const buttonSize = "1.03em"

  return (

            <header style={{color: "white", display: "flex", justifyContent: "center", paddingTop:"30px"}}>
                <Button style={{color: "white", fontSize: buttonSize, textTransform:"unset"}} onClick={() => window.location.href="/"}>Home</Button>

                <Button aria-controls={roomsMenuOpen ? 'rooms-menu' : undefined} aria-haspopup="true" aria-expanded={roomsMenuOpen ? 'true' : undefined} onClick={e => setRoomsAnchorEl(e.currentTarget)} size={"small"} style={{marginLeft: "30px", marginRight: "30px", color: "white", textTransform:"unset", fontSize: buttonSize}}>Amenities</Button>
                <Menu anchorEl={roomsAnchorEl} open={roomsMenuOpen} onClose={() => setRoomsAnchorEl(null)}>
                    <MenuItem onClick={() => window.location.href = "/games-room/"}>Games Room</MenuItem>
                    <MenuItem onClick={() => window.location.href = "/spa/"}>Spa</MenuItem>
                    <MenuItem onClick={() => window.location.href = "/playground/"}>Playground</MenuItem>
                    <MenuItem onClick={() => window.location.href = "/accommodation/"}>Accommodation</MenuItem>
                    <MenuItem onClick={() => window.location.href = "/location/"}>Location</MenuItem>
                </Menu>

                <Button style={{color: "white", textTransform:"unset", fontSize: buttonSize}} onClick={() => window.location.href="/reservation"}>Book Now</Button>
            </header>
  );
}

export default InnerHeader;
