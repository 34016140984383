import './App.css';
import {Button, Grid} from "@mui/material";
import {
    gapBetweenSections, StyledColouredLongDescriptorBlock,
    StyledColouredShortDescriptorBlock,
    StyledImageBlock, StyledWhiteLongDescriptorBlock,
    themeTextColorOnWhite, titleFontHeadingSize
} from "./index";
import StandardFrame from "./StandardFrame";
import SearchAvailabilityControl from "./SearchAvailabilityControl";

function Home() {

    {/*TODO: replace cottage everywhere?*/}

  return (
      <StandardFrame preHeaderText={<div>Welcome to Cayton Bay</div>} headerText={<div>Family Holiday Home</div>}
      headerImageUrl={"url('/beach/beach.jpg')"}
      mainBody={
            <div>

                <StyledWhiteLongDescriptorBlock style={{justifyContent: "center"}}>
                    <SearchAvailabilityControl navigateToReservationPage={true} />

                    <h2 style={{paddingTop:"40px", color: themeTextColorOnWhite, textAlign: "center"}}>The Complete Holiday Home for the Entire Family</h2>

                    <h3 style={{fontStyle:"italic", fontSize:"0.9rem", marginTop:"-20px", textAlign: "center"}}>"Private Spa, Games Room, Panoramic Sea Views, a short walk onto to Cayton Bay Beach and a 45 minutes walk along the Cleveland Way into Scarborough"</h3>
                    {/*<div style={{color: "red", textAlign: "center"}}>Currently undergoing refurbishment and arriving for bookings early in 2023.</div>*/}
                    <div style={{marginTop:"0px", marginBottom:"50px", textAlign: "center"}}>Situated on the North West side of Cayton Bay, our beautiful holiday home at Knipe Point is the ultimate family destination.</div>
                </StyledWhiteLongDescriptorBlock>

                <Grid container style={{marginTop:gapBetweenSections}}>
                    <Grid item xs={12} sm={4}>
                        <StyledColouredShortDescriptorBlock>
                            Our <br />Games Room
                        </StyledColouredShortDescriptorBlock>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <StyledImageBlock style={{backgroundImage: "url('/games-room/games-room-inside1.jpg')"}}></StyledImageBlock>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <StyledImageBlock style={{backgroundImage: "url('/games-room/games-room-outside1.jpg')"}}></StyledImageBlock>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <StyledWhiteLongDescriptorBlock>
                            <div style={{textAlign: "left"}}>
                                Our charming Log Cabin games room is complete with a pool table, table football and retro arcade machines.
                                <div style={{marginTop:"15px"}}><Button style={{padding:0}} onClick={() => window.location.href = "/games-room/"}>Find out more</Button></div>
                            </div>
                        </StyledWhiteLongDescriptorBlock>
                    </Grid>

                </Grid>

                <Grid container style={{marginTop:gapBetweenSections}}>
                    <Grid item xs={12} sm={8}>
                        <StyledImageBlock style={{backgroundImage: "url('/spa/outside1.jpg')"}}></StyledImageBlock>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <StyledColouredShortDescriptorBlock>
                            Our<br />Private Spa
                        </StyledColouredShortDescriptorBlock>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <StyledWhiteLongDescriptorBlock>
                            <div>
                                Our holiday home has a private garden spa with a barrel sauna and hot tub to ensure a relaxing holiday.
                                <div style={{marginTop:"15px"}}><Button style={{padding:0}} onClick={() => window.location.href = "/spa/"}>Find out more</Button></div>
                            </div>
                        </StyledWhiteLongDescriptorBlock>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <StyledImageBlock style={{backgroundImage:"url('/spa/sauna-inside1.jpg')"}}></StyledImageBlock>
                    </Grid>
                </Grid>

                <Grid container style={{marginTop:gapBetweenSections}}>
                    <Grid item xs={12} sm={4}>
                        <StyledImageBlock style={{backgroundImage:"url('/playground/playground1.jpg')"}}></StyledImageBlock>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <StyledColouredLongDescriptorBlock>

                            {/*Check age*/}
                            <div>
                                <div style={{fontSize: titleFontHeadingSize}}>Our Play Equipment</div>
                                <div>Our own safe and secure play equipment for children aged between two and ten years.</div>
                                <div style={{marginTop:"15px"}}><Button style={{padding:0, color: "white"}} onClick={() => window.location.href = "/playground/"}>Find out more</Button></div>
                            </div>
                        </StyledColouredLongDescriptorBlock>
                    </Grid>
                </Grid>


                <Grid container style={{marginTop:gapBetweenSections}}>
                    <Grid item xs={12} sm={4}>
                        <StyledColouredShortDescriptorBlock>
                            Location
                        </StyledColouredShortDescriptorBlock>
                    </Grid>
                    {/*<Grid item xs={8}>*/}
                    {/*    <StyledImageBlock style={{}} />*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={4}>*/}
                    {/*    <StyledImageBlock style={{}} />*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} sm={8}>
                        <StyledWhiteLongDescriptorBlock>
                            <div>
                                <div>With panoramic sea views stretching from Scarborough Castle to Filey Brigg we are:</div>
                                <ul>
                                    <li>Overlooking the sea from an elevated position</li>
                                    <li>Ten minutes walk onto the <a href={"/beach"}>Cayton Bay Beach</a></li>
                                    <li>Three miles southeast of Scarborough</li>
                                    <li>Four miles northwest of Filey</li>
                                </ul>
                                <div style={{marginTop:"15px"}}><Button style={{padding:0}} onClick={() => window.location.href = "/location/"}>Find out more</Button></div>
                            </div>
                        </StyledWhiteLongDescriptorBlock>
                    </Grid>
                </Grid>

                <Grid container style={{marginTop:gapBetweenSections}}>
                    <Grid item xs={12} sm={8}>
                        <StyledImageBlock style={{backgroundImage:"url('/house/outside1.jpg')"}}></StyledImageBlock>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <StyledColouredShortDescriptorBlock>
                            Our<br />Bungalow
                        </StyledColouredShortDescriptorBlock>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <StyledWhiteLongDescriptorBlock>
                            <div>
                                <div>Beautifully decorated bungalow with external log cabin games room, spa, playground and parking, that was beautifully refurbished for 2023.</div>
                                <div>The main building contains a modern kitchen, dining room, living room, two triple bedrooms and bathroom.</div>
                                <div style={{marginTop:"15px"}}><Button style={{padding:0}} onClick={() => window.location.href = "/accommodation/"}>Find out more</Button></div>
                            </div>
                        </StyledWhiteLongDescriptorBlock>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <StyledImageBlock style={{backgroundImage:"url('/house/living-room-simulation1.jpg')"}}></StyledImageBlock>
                    </Grid>
                </Grid>
            </div>
        } />
  );
}

export default Home;
