import './App.css';
import {ChildCare, DirectionsWalk, DriveEta, Image, PedalBike, Straighten, Stroller} from "@mui/icons-material";
import {Button, Card, Grid, Paper, styled} from "@mui/material";
import StandardFrame from "./StandardFrame";
import {sectionHeight, StyledWhiteLongDescriptorBlock, themeTextColorOnWhite} from "./index";

function Location() {

    const StyledLine = styled("div")({
        display: "flex",
        marginTop:"5px",
        marginLeft: "10px",
        "& svg": {
            marginRight:"10px"
        }
    })


  return (
      <StandardFrame headerText={"Location"} headerImageUrl={"url('/house/outside1.jpg')"}
                     mainBody={
                         <StyledWhiteLongDescriptorBlock>
                             <div>
                                 <h2>Perfectly Situated on the North East Coast</h2>
                                 <div>
                                     Our <a href={"/"}>holiday home</a> is conveniently located at the top of Knipe Point, with panoramic sea views from Scarborough Castle down past Cayton Bay towards Filey.
                                 </div>
                                 <div>
                                     <h3>Arguably the best beach on the east coast (aka. Cayton Bay)</h3>
                                     <StyledLine><DirectionsWalk/>A short walk down a stepped path to the northwest end of the beach.</StyledLine>
                                     <StyledLine><Stroller/> 15-20 mins walk down a fairly gentle slope to the central area of the beach.</StyledLine>
                                     <StyledLine><DriveEta/> There is a car park near the </StyledLine>
                                 </div>
                                 <div>
                                     <h3>Scarborough</h3>
                                     <StyledLine><Straighten /> 2 miles (north)</StyledLine>
                                     <StyledLine><DirectionsWalk/> 45 minutes walk down the Cleveland way</StyledLine>
                                     <StyledLine><DriveEta/> 5 minutes drive</StyledLine>
                                 </div>
                                 <div>
                                     <h3>Filey</h3>
                                     <StyledLine><Straighten /> 6 miles (south)</StyledLine>
                                     <StyledLine><DirectionsWalk/> 90-minute walk the opposite way down the Cleveland way</StyledLine>
                                     <StyledLine><PedalBike/> 15 minutes cycle</StyledLine>
                                     <StyledLine><DriveEta/> 10 minutes drive</StyledLine>
                                 </div>
                                 <div>
                                     <h3>Sea Life Centre (North Scarborough)</h3>
                                     <StyledLine><Straighten /> 4.5 miles (north)</StyledLine>
                                     <StyledLine><DriveEta/> 15 minutes drive</StyledLine>
                                 </div>
                                 <div>
                                     <h3>Bempton Cliffs / Flamborough</h3>
                                     <StyledLine><Straighten /> 15 miles (south)</StyledLine>
                                     <StyledLine><PedalBike/> Just over one hour cycle (at my average speed at least!)</StyledLine>
                                     <StyledLine><DriveEta/> 25 minutes drive</StyledLine>
                                 </div>
                                 <div>
                                     <h3>Robin Hoods Bay</h3>
                                     <StyledLine><Straighten /> 19 miles (north)</StyledLine>
                                     <StyledLine><DriveEta/> 35 minutes drive</StyledLine>
                                 </div>
                                 <div>
                                     <h3>North Yorkshire Water Park</h3>
                                     <StyledLine><Straighten /> 8 miles (west)</StyledLine>
                                     <StyledLine><DriveEta/> 20 minutes drive</StyledLine>
                                 </div>
                                 <div>
                                     <h3>Bridlington</h3>
                                     <StyledLine><Straighten /> 16 miles (south)</StyledLine>
                                     <StyledLine><DriveEta/> 25 minutes drive</StyledLine>
                                 </div>
                                 <div>
                                     <h3>Whitby</h3>
                                     <StyledLine><Straighten /> 22 miles (north)</StyledLine>
                                     <StyledLine><DriveEta/> 40 minutes drive</StyledLine>
                                 </div>
                                 <div>
                                     <h3>Castle Howard</h3>
                                     <StyledLine><Straighten /> 30 miles (west)</StyledLine>
                                     <StyledLine><DriveEta/> 45 minutes drive</StyledLine>
                                 </div>
                                 <div>
                                     <h3>Flamingo Land</h3>
                                     <StyledLine><Straighten /> 22 miles (west)</StyledLine>
                                     <StyledLine><DriveEta/> 40 minutes drive</StyledLine>
                                 </div>

                                 <h2>Convenience</h2>
                                 <div>
                                     <h3>Nearest Playground</h3>
                                     <StyledLine><ChildCare /><span>Our holiday home has a private <a href={"/playground"}>playground</a> in the back garden.</span></StyledLine>
                                 </div>
                                 <div>
                                     <h3>Playdale Farm</h3>
                                     <StyledLine><span>Fantastic for kids, <a href={"https://playdalefarmpark.co.uk/"}>Playdale Farm</a> has an animal barn (with interactive paddocks), a huge indoor play area / soft play, a tractor zone and an adventure trail.</span></StyledLine>
                                     <StyledLine><DriveEta />9 mins drive</StyledLine>
                                 </div>
                                 <div>
                                     <h3>Redcliffe Farm Shop & Cafe</h3>
                                     <StyledLine><span><a href={"https://redcliffefarmshop.co.uk/"}>Redcliffe Farm Shop & Cafe</a> is a local farm shop selling high-quality produce.</span></StyledLine>
                                     <StyledLine><DriveEta /> 8 mins drive</StyledLine>
                                 </div>

                                 <br />
                             </div>

                         </StyledWhiteLongDescriptorBlock>
                     } />
  );
}

export default Location;
