import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Beach from "./Beach";
import Home from "./Home";
import GamesRoom from "./GamesRoom";
import Spa from "./Spa";
import Playground from "./Playground";
import {createTheme, CssBaseline, styled, ThemeProvider} from "@mui/material";
import Accommodation from "./Accommodation";
import Location from "./Location";
import Reservation from "./Reservation";


export const themeTextColorOnWhite = "grey"
export const themeColour1 = "rgb(105,165,165)"
const theme = createTheme({
    palette: {
        background: {
            default: "#eee"
        },
        primary: {
            main: themeColour1,
        },
    },
    typography: {
        fontFamily: "ralewayFontFromGoogle"

    }
})

export const sectionHeight = "250px"
export const gapBetweenSections = "70px"
export const titleFontHeadingSize = "1.6rem"

export const StyledImageBlock = styled("div")({
    height:sectionHeight,
    backgroundSize:"cover",
    backgroundPosition:"center"
})
export const StyledColouredShortDescriptorBlock = styled("div")({
    minHeight: sectionHeight,
    backgroundColor:themeColour1,
    height:"100%",
    padding: "20px",
    display: "flex",
    alignItems:"center",
    justifyContent:"center",
    color: "white",
    fontSize: titleFontHeadingSize
})
export const StyledColouredLongDescriptorBlock = styled("div")({
    minHeight: sectionHeight,
    height:"100%",
    backgroundColor:themeColour1,
    display: "flex",
    alignItems:"center",
    flexWrap:"wrap",
    padding: "40px",
    textAlign: "left",
    color: "white",
})
export const StyledWhiteLongDescriptorBlock = styled("div")({
    minHeight: sectionHeight,
    height:"100%",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    paddingLeft:"40px",
    paddingRight:"40px",
    paddingTop:"10px",
    paddingBottom:"10px",
    textAlign: "left",
    color: themeTextColorOnWhite,
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<Home />}/>
                  <Route path="/beach" element={<Beach />}/>
                  <Route path="/accommodation" element={<Accommodation />}/>
                  <Route path="/location" element={<Location />}/>
                  <Route path="/games-room" element={<GamesRoom />}/>
                  <Route path="/spa" element={<Spa />}/>
                  <Route path="/playground" element={<Playground />}/>
                  <Route path="/reservation" element={<Reservation />}/>
                  <Route path="/*" element={<Navigate to={"/"} />}/>
              </Routes>
          </BrowserRouter>
      </ThemeProvider>
  </React.StrictMode>
)

