import './App.css';
import StandardFrame from "./StandardFrame";
import {StyledWhiteLongDescriptorBlock, themeColour1} from "./index";
import {BeachAccess} from "@mui/icons-material";

function Beach() {
  return (
      // TODO: where is this called?
      <StandardFrame headerText={"Cayton Bay Beach"} headerImageUrl={"url('/beach/beach.jpg')"}
          mainBody={
              <StyledWhiteLongDescriptorBlock>
                  <div>
                  <div><BeachAccess style={{fontSize:"50px", color: themeColour1}} />The beautiful two-kilometre Cayton Bay Beach is situated three miles southeast of Scarborough and four miles northwest of Filey.</div>
                  <div>During peak times, the beach is lifeguarded -> schedule can be found at <a href={"https://rnli.org/find-my-nearest/lifeguarded-beaches/cayton-bay-beach"}>RNLI (external link)</a>.</div>

                  <div>Situated on the Cleveland way, the two-kilometre Cayton Bay Beach is three miles southeast of Scarborough and four miles northwest of Filey.</div>

                  <h2>Activities</h2>
                      <div>Paddle Boarding</div>
                      <div>Surfing</div>
                      <div>... and all the usual beach stuff!</div>
                  {/*TODO:*/}
                  </div>
              </StyledWhiteLongDescriptorBlock>
          } />
  );
}

export default Beach;
