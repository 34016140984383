import './App.css';
import {Button, Grid, TextField} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRange } from 'react-date-range'
import {addDays, format} from "date-fns";

function SearchAvailabilityControl({}) {
    const [range, setRange] = useState({startDate: null, endDate: null, key: 'selection'})
    const [isOpen, setIsOpen] = useState(false)
    const [, setUnusedValueForRerender] = useState(0)
    const triggerRender = () => setUnusedValueForRerender(value => value + 1)
    const refForCalendarControl = useRef(null)
    const [disabledDates, setDisabledDates] = useState([])

    useEffect(() => {
        document.addEventListener("keydown", hideOnEscape, true)
        document.addEventListener("click", hideOnClickOutside, true)
    }, [])


    useEffect(() => {
        setTimeout(updateDefaultDates, 50)
    },[])

    function updateDefaultDates() {
        const today = new Date()

        disabledDates.push(today)
        disabledDates.push(addDays(today, 1))

        const internalDateFormat = "dd-MM-yyyy"
        const disabledDateStrings = disabledDates.map(disabledDate => (format(disabledDate, internalDateFormat)))

        //TODO: populate disableDates with bookings

        for (let i=0; i<100; i++) {
            const possibleDate = addDays(today, i)
            const possibleDateString = format(possibleDate, internalDateFormat)
            if (possibleDate.getDay() === 1 || possibleDate.getDay() === 5) {
                if (!range.endDate && range.startDate) {
                    if (!disabledDateStrings.includes(possibleDateString)) {
                        range.endDate = possibleDate
                    }
                }
                if (!range.startDate) {
                    if (!disabledDateStrings.includes(possibleDateString)) {
                        range.startDate = possibleDate
                    }
                }
            }
        }
        triggerRender()
    }

    function hideOnEscape(e) {
        console.log(e.key)
        if (e.key === "Escape")
            setIsOpen(false)
    }

    function hideOnClickOutside(e) {
        console.log(refForCalendarControl.current)
        console.log(e.target)
        if (refForCalendarControl.current && !refForCalendarControl.current.contains(e.target)) {
            setIsOpen(false)
        }
    }

    function toDateString(d) {
        return d ? format(d, "dd/MM/yyyy") : ""
    }

    function isCheckInDayFunc(date) {
        if (!date)
            return true
        else
            return date.getDay() === 1 || date.getDay() === 5
    }
    function isValidInformalRange(selection) {
        return isCheckInDayFunc(selection.startDate) && isCheckInDayFunc(selection.endDate)
    }

    function customDayContent(day) {
        const isCheckInDay = isCheckInDayFunc(day)
        return (
            <span style={{width: "100%", height:"fit-content", cursor: isCheckInDay?"pointer":"not-allowed"}}>
                {
                    isCheckInDay
                        ? <span style={{fontWeight: "bold"}}>
                            {/*<div style={{ height: "5px", width: "5px", borderRadius: "100%", background: "green", position: "absolute", top: 2, right: 2 }} />*/}
                            {format(day, "d")}
                        </span>
                        : <span style={{color: "lightgray"}}>
                            {format(day, "d")}
                        </span>
                }

            </span>
        )
    }
    function onSearch() {
        alert("The accommodation is currently under refurbishment and booking has been disabled. Please check back soon!")
    }

    return (
        <Grid container spacing={1} style={{fontSize: "0.8rem"}}>
            <Grid item xs={12} sm={4}>
                Check-In
                <TextField value={toDateString(range.startDate)} size={"small"} onClick={() => setIsOpen(isOpen => !isOpen)} fullWidth autoComplete={"off"} />

            </Grid>
            <Grid item xs={12} sm={4}>
                Check-Out
                <TextField value={toDateString(range.endDate)} size={"small"} onClick={() => setIsOpen(isOpen => !isOpen)} fullWidth autoComplete={"off"} />

            </Grid>
            <Grid item xs={12} sm={4}>
                <div style={{position: "relative", minHeight: "60px"}}>
                    <Button style={{color: "white", bottom: "3px", position: "absolute"}} variant={"contained"} fullWidth onClick={onSearch}>Check Price</Button>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div ref={refForCalendarControl} style={{width:"fit-content", position: "absolute"}}>
                    {
                        isOpen && <DateRange
                            onChange={d => { if (isValidInformalRange(d.selection)) setRange(d.selection) } }
                            editableDateInputs={true}
                            moveRangeOnFirstSelection={false}
                            disabledDates={disabledDates}
                            minDate={new Date()}
                            ranges={[range]}
                            months={2}
                            dayContentRenderer={customDayContent}
                            direction={"vertical"}
                            showDateDisplay={false}
                            tileClassName={({ date, view }) => {
                                    return  'highlight'
                            }}
                        />
                    }
                </div>
            </Grid>
        </Grid>

    );
}

export default SearchAvailabilityControl;
