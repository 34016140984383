import './App.css';
import {DirectionsWalk, DriveEta} from "@mui/icons-material";
import StandardFrame from "./StandardFrame";
import {Paper} from "@mui/material";
import {StyledWhiteLongDescriptorBlock, themeTextColorOnWhite} from "./index";

function Spa() {
  return (
      <StandardFrame headerText={"Your Private Spa"} headerImageUrl={"url('/spa/outside1.jpg')"}
                     mainBody={
                         <StyledWhiteLongDescriptorBlock>
                             <div>
                                 <h2>Our Private Spa</h2>
                                 <div>Luxury Spa, complete with a Hot Tub and Sauna.</div>
                             </div>
                         </StyledWhiteLongDescriptorBlock>
                     } />
  );
}
export default Spa;
